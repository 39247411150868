/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import type { GatsbyBrowser } from 'gatsby';

import './src/assets/styles/tailwind.css';

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  console.log(
    `%c
    ######  ### #     #  #####  ### #######
    #     #  #  ##    # #     #  #     #
    #     #  #  # #   # #        #     #
    ######   #  #  #  # #  ####  #     #
    #   #    #  #   # # #     #  #     #
    #    #   #  #    ## #     #  #     #
    #     # ### #     #  #####  ###    #

    Interested in what is happening here?
    Let's chat gert@ringit.ee
  `,
    'font-family: monospace;',
  );
};
